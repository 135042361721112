import React from 'react';
import { BsInstagram, BsWechat, BsWhatsapp } from 'react-icons/bs';
import { FaFacebookF } from 'react-icons/fa';
import './MobileSocialMedia.scss';
import Wechat from './wechatQR.jpg';

const MobileSocialMedia = () => (
  <div className="app__mobile-social">
    <div className='circle-container'>
      <a href="https://wa.link/1n4uev" target="_blank" rel="noopener noreferrer">
        <BsWhatsapp />
      </a >
    </div>
    <div className='circle-container'>
      <a href="" target="_blank" rel="noopener noreferrer">
        <BsWechat />
        <div className="hover-box">
          < img src={Wechat} alt="WeChat" />
        </div>
      </a >
    </div>
    <div className='circle-container'>
      <a href="https://www.facebook.com/profile.php?id=100004751508239&mibextid=9R9pXO" target="_blank" rel="noopener noreferrer">
        <FaFacebookF />
      </a >
    </div>
    <div className='circle-container'>
      <a href="https://www.instagram.com/_oulimey_?igsh=OGQ5ZDc2ODk2ZA%3D%3D&utm_source=qr" target="_blank" rel="noopener noreferrer">
        <BsInstagram />
      </a >
    </div>
  </div>
);

export default MobileSocialMedia;